<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <h4 class="mb-3">
        Черновик водителя — {{ data.originalDriver.surname }} {{ data.originalDriver.name }} <span v-if="data.originalDriver.patronymic">
          {{ data.originalDriver.patronymic }}
        </span>
        <span v-if="!data.draftDriver.type">
          <span
            v-if="data.draftDriver.status === 0"
            class="text-secondary"
          > (новый) </span>
          <span
            v-if="data.draftDriver.status === 1"
            class="text-success"
          > (принят) </span>
          <span
            v-if="data.draftDriver.status === 2"
            class="text-danger"
          > (отклонен) </span>
        </span>
        <span v-else>
          <span
            v-if="data.originalDriver.isCancel"
            class="text-danger"
          > (пометка на удаление)</span>
          <span v-else>
            <span
              v-if="data.originalDriver.status === 0"
              class="text-secondary"
            > (заблокирован СБ) </span>
            <span
              v-if="data.originalDriver.status === 1"
              class="text-success"
            > (допущен) </span>
            <span
              v-if="data.originalDriver.status === 2"
              class="text-primary"
            > (на верификации) </span>
            <span
              v-if="data.originalDriver.status === 3"
              class="text-danger"
            > (не допущен) </span>
            <span
              v-if="data.originalDriver.status === 4"
              class="text-primary"
            > (проверка логистом) </span>
            <span
              v-if="data.originalDriver.status === 5"
              class="text-danger"
            > (отклонен) </span>
            <span
              v-if="data.originalDriver.driverBlockedSecurity"
              class="badge badge-danger"
            >Блокировка СБ</span>
          </span>
        </span>
      </h4>
      <p v-if="!data.draftDriver.type">
        Пользователь перевозчика произвел попытку добавления водителя, проверьте введенные данные и примите решение
      </p>
      <p v-else>
        Пользователь перевозчика внес изменения в данные водителя, проверьте введенные данные и примите решение
      </p>
      <p>Перевозчик - <b>{{ data.contractor.name }}</b></p>
      <p>Пользователь - <b>{{ data.createdBy.username }}</b></p>
      <div class="form-sections">
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Паспортные данные
          </h5>
          <b-row>
            <b-col md="6">
              <h6 class="custom-fieldset__title">
                Данные введенные пользователем
              </h6>
            </b-col>
            <b-col md="6">
              <h6 class="custom-fieldset__title">
                Данные существующего водителя
              </h6>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Паспорт"
                label-for="passport-type"
              >
                <el-select
                  v-model="data.draftDriver.passportType"
                  disabled
                  class="form-control"
                  :class="{'is-invalid' : data.draftDriver.passportType !== data.originalDriver.passportType}"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                class="form-group-el-select"
                label="Паспорт"
                label-for="passport-type"
              >
                <el-select
                  v-model="data.originalDriver.passportType"
                  disabled
                  class="form-control"
                  name="passport-type"
                  placeholder="Выберите тип паспорта"
                  clearable
                  :loading="loading"
                >
                  <el-option
                    v-for="item in passportTypes"
                    :key="item.value"
                    :label="item.text"
                    :value="item.value"
                  />
                </el-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      v-model="data.draftDriver.passportSeries"
                      :class="{'is-invalid' : data.draftDriver.passportSeries !== data.originalDriver.passportSeries}"
                      disabled
                      type="text"
                      name="passport-series"
                      autocomplete="passport-series"
                    />
                    <b-form-invalid-feedback>
                      Это поле отличается от оригинала
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      v-model="data.draftDriver.passportNumber"
                      :class="{'is-invalid' : data.draftDriver.passportNumber !== data.originalDriver.passportNumber}"
                      disabled
                      type="number"
                      name="passport-number"
                      autocomplete="passport-number"
                    />
                    <b-form-invalid-feedback>
                      Это поле отличается от оригинала
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Серия"
                    label-for="passport-series"
                  >
                    <b-form-input
                      v-model="data.originalDriver.passportSeries"
                      disabled
                      type="number"
                      name="passport-series"
                      autocomplete="passport-series"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="8">
                  <b-form-group
                    label="Номер"
                    label-for="passport-series"
                  >
                    <b-form-input
                      v-model="data.originalDriver.passportNumber"
                      disabled
                      type="number"
                      name="passport-number"
                      autocomplete="passport-number"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <el-date-picker-input
                  v-model="data.draftDriver.passportIssueDate"
                  :class="{'is-invalid' : data.draftDriver.passportIssueDate !== data.originalDriver.passportIssueDate}"
                  disabled
                  type="date"
                  name="passport-issue-date"
                  autocomplete="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Дата выдачи"
                label-for="passport-issue-date"
              >
                <el-date-picker-input
                  v-model="data.originalDriver.passportIssueDate"
                  disabled
                  type="date"
                  name="passport-issue-date"
                  autocomplete="passport-issue-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  v-model="data.draftDriver.passportIssuedBy"
                  :class="{'is-invalid' : data.draftDriver.passportIssuedBy !== data.originalDriver.passportIssuedBy}"
                  disabled
                  type="text"
                  name="passport-issued-by"
                  autocomplete="passport-issued-by"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Кем выдан"
                label-for="passport-issued-by"
              >
                <b-form-input
                  v-model="data.originalDriver.passportIssuedBy"
                  disabled
                  type="text"
                  name="passport-issued-by"
                  autocomplete="passport-issued-by"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  v-model="data.draftDriver.surname"
                  :class="{'is-invalid' : data.draftDriver.surname.toLowerCase() !== data.originalDriver.surname.toLowerCase()}"
                  disabled
                  type="text"
                  name="surname"
                  autocomplete="surname"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Фамилия"
                label-for="surname"
              >
                <b-form-input
                  v-model="data.originalDriver.surname"
                  disabled
                  type="text"
                  name="surname"
                  autocomplete="surname"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Имя"
                label-for="name"
              >
                <b-form-input
                  v-model="data.draftDriver.name"
                  :class="{'is-invalid' : data.draftDriver.name.toLowerCase() !== data.originalDriver.name.toLowerCase()}"
                  disabled
                  type="text"
                  name="name"
                  autocomplete="name"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Имя"
                label-for="name"
              >
                <b-form-input
                  v-model="data.originalDriver.name"
                  disabled
                  type="text"
                  name="name"
                  autocomplete="name"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  v-model="data.draftDriver.patronymic"
                  :class="{'is-invalid' : data.draftDriver.patronymic.toLowerCase() !== data.originalDriver.patronymic.toLowerCase()}"
                  disabled
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Отчество"
                label-for="patronymic"
              >
                <b-form-input
                  v-model="data.originalDriver.patronymic"
                  disabled
                  type="text"
                  name="patronymic"
                  autocomplete="patronymic"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="birthDateInputGroup19"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birth-date"
                  v-model="data.draftDriver.birthDate"
                  disabled
                  type="date"
                  name="birth-date"
                  autocomplete="birth-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="birthDateInputGroup19"
                label="Дата рождения"
                label-for="birth-date"
              >
                <el-date-picker-input
                  id="birth-date"
                  v-model="data.originalDriver.birthDate"
                  disabled
                  type="date"
                  name="birth-date"
                  autocomplete="birth-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Главная страница паспорта"
                label-for="passport-main-page"
              >
                <b-form-file
                  v-model="data.draftDriver.passportScreenFirst"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-main-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="data.draftDriver.passportScreenFirst && data.draftDriver.passportScreenFirst.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + data.draftDriver.passportScreenFirst.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(data.draftDriver.passportScreenFirst.file)"
                  >{{ data.draftDriver.passportScreenFirst.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Главная страница паспорта"
                label-for="passport-main-page"
              >
                <b-form-file
                  v-model="data.originalDriver.passportScreenFirst"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-main-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="data.originalDriver.passportScreenFirst && data.originalDriver.passportScreenFirst.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + data.originalDriver.passportScreenFirst.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(data.originalDriver.passportScreenFirst.file)"
                  >{{ data.originalDriver.passportScreenFirst.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Страница с пропиской"
                label-for="passport-registration-page"
              >
                <b-form-file
                  v-model="data.draftDriver.passportScreenSecond"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="data.draftDriver.passportScreenSecond && data.draftDriver.passportScreenSecond.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + data.draftDriver.passportScreenSecond.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(data.draftDriver.passportScreenSecond.file)"
                  >{{ data.draftDriver.passportScreenSecond.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Страница с пропиской"
                label-for="passport-registration-page"
              >
                <b-form-file
                  v-model="data.originalDriver.passportScreenSecond"
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="passport-registration-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Файл не выбран"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                />
                <div
                  v-if="data.originalDriver.passportScreenSecond && data.originalDriver.passportScreenSecond.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + data.originalDriver.passportScreenSecond.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(data.originalDriver.passportScreenSecond.file)"
                  >{{ data.originalDriver.passportScreenSecond.file.originalName }}</a>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Документы договорных отношений водителя и работодателя"
              >
                <b-form-file
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="contract-file"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="data.draftDriver.contractFiles.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in data.draftDriver.contractFiles"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Документы договорных отношений водителя и работодателя"
              >
                <b-form-file
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="contract-file"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="data.originalDriver.contractorDrivers.length && data.originalDriver.contractorDrivers[0].contractFiles.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in data.originalDriver.contractorDrivers.length && data.originalDriver.contractorDrivers[0].contractFiles"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  v-model="data.draftDriver.driverLicense"
                  :class="{'is-invalid' : data.draftDriver.driverLicense !== data.originalDriver.driverLicense}"
                  disabled
                  type="text"
                  name="drivers-license"
                  autocomplete="drivers-license"
                />
                <b-form-invalid-feedback>
                  Это поле отличается от оригинала
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Номер водительского удостоверения"
                label-for="drivers-license"
              >
                <b-form-input
                  v-model="data.originalDriver.driverLicense"
                  disabled
                  type="text"
                  name="drivers-license"
                  autocomplete="drivers-license"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Водительское удостоверение"
              >
                <b-form-file
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="drivers-license-cover-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="data.draftDriver.driverLicenseScreens.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in data.draftDriver.driverLicenseScreens"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Водительское удостоверение"
              >
                <b-form-file
                  v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                  disabled
                  name="drivers-license-cover-page"
                  accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                  placeholder="Выберите файл"
                  browse-text="Выбрать"
                  drop-placeholder="Перетащите файл сюда"
                  multiple
                />
                <div
                  v-if="data.originalDriver.contractorDrivers.length && data.originalDriver.contractorDrivers[0].driverLicenseScreens.length > 0"
                  class="mt-2"
                >
                  Загруженные файлы:
                  <span
                    v-for="(file, index) in data.originalDriver.contractorDrivers.length && data.originalDriver.contractorDrivers[0].driverLicenseScreens"
                    :key="`file-${index}`"
                  >
                    <a
                      :href="'/files/' + file.file.guid"
                      target="_blank"
                      @click.prevent="downloadFile(file.file)"
                    >{{ file.file.originalName }}</a>;
                  </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Контактная информация
          </h5>
          <p>Перевозчик указал следующие контактные данные по водителю:</p>
          <div
            v-for="(phone, index) in data.driverPhones"
            :key="`phone-${index}`"
          >
            +{{ phone.countryCode.code }} ({{ phone.areaCode }})-{{ phone.localNumber }}
            <span
              v-if="phone.additionalCode"
            >({{ phone.additionalCode }})</span>
          </div>
        </div>
        <div class="custom-fieldset">
          <h5 class="custom-fieldset__title">
            Данные по допускам
          </h5>
          <b-row>
            <b-col md="6">
              <b-form-checkbox
                v-model="data.draftDriver.driverAccessAuto"
                disabled
                name="access-auto"
              >
                Допуск Авто
              </b-form-checkbox>
              <b-form-checkbox
                v-model="data.draftDriver.driverAccessContainer"
                disabled
                name="access-container"
              >
                Допуск Контейнер
              </b-form-checkbox>
              <b-form-checkbox
                v-model="data.draftDriver.driverAccessCargoFlow"
                disabled
                name="access-cargo-flow"
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
            <b-col md="6">
              <b-form-checkbox
                v-model="data.originalDriver.driverAccessAuto"
                disabled
                name="access-auto"
              >
                Допуск Авто
              </b-form-checkbox>
              <b-form-checkbox
                v-model="data.originalDriver.driverAccessContainer"
                disabled
                name="access-container"
              >
                Допуск Контейнер
              </b-form-checkbox>
              <b-form-checkbox
                v-model="data.originalDriver.driverAccessCargoFlow"
                disabled
                name="access-cargo-flow"
              >
                Допуск Грузопровод
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <!--div class="custom-fieldset">
          <h5 class="custom-fieldset__title">Данные по доверенностям</h5>
          <div class="proxy-block" v-if="form.attorneys !== []" v-for="(attorney, index) in form.attorneys" :key="index">
            <h6>Доверенность №{{form.attorneys[index].number}}</h6>
            <b-row>
              <b-col md="6">
                <b-form-group :id="'attorneyNumberInputGroup' + index" label="Номер доверенности" :label-for="'attorney-number-' + index">
                  <b-form-input
                    :id="'attorney-number-' + index"
                    type="text"
                    :name="'attorney-number-' + index"
                    v-model.trim="form.attorneys[index].number"
                    :aria-describedby="'attorneyNumberLiveFeedback' + index"
                    autocomplete="attorney-number"
                    required />
                  <b-form-invalid-feedback :id="'attorneyNumberLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :id="'attorneyDateInputGroup' + index" label="Дата доверенности" :label-for="'attorney-date-' + index">
                  <b-form-input
                    :id="'attorney-date-' + index"
                    type="date"
                    :name="'attorney-date-' + index"
                    v-model.trim="form.attorneys[index].date"
                    :aria-describedby="'attorneyDateLiveFeedback' + index"
                    autocomplete="attorney-date"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyDateStartInputGroup' + index" label="Период действия с" :label-for="'attorney-date-start-' + index">
                  <b-form-input
                    :id="'attorney-date-start-' + index"
                    type="date"
                    :name="'attorney-date-start-' + index"
                    v-model.trim="form.attorneys[index].dateStart"
                    :aria-describedby="'attorneyDateStartLiveFeedback' + index"
                    autocomplete="attorney-date-start"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateStartLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyDateEndInputGroup' + index" label="Период действия по" :label-for="'attorney-date-end-' + index">
                  <b-form-input
                    :id="'attorney-date-end-' + index"
                    type="date"
                    :name="'attorney-date-end-' + index"
                    v-model.trim="form.attorneys[index].dateEnd"
                    :aria-describedby="'attorneyDateEndLiveFeedback' + index"
                    autocomplete="attorney-date-end"
                    required />
                  <b-form-invalid-feedback :id="'attorneyDateEndLiveFeedback' + index">
                    Это поле обязательно для заполнения
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group :id="'attorneyInputGroup' + index" label="Доверенность" :label-for="'attorney' + index">
                  <b-form-file
                    :id="'attorney-' + index"
                    :name="'attorney-' + index"
                    v-model="form.attorneys[index].file"
                    v-b-popover.hover="'Максимальный объем загружаемого файла, не должен превышать 15MB'"
                    accept=".txt, .rtf, .doc, .docx, .pdf, .rar, .zip, .xls, .xlsx, .jpg, .jpeg, .png, .svg"
                    placeholder="Файл не выбран"
                    browse-text="Выбрать"
                    drop-placeholder="Перетащите файл сюда"
                    @input="uploadAttorneyFile(index)"
                  ></b-form-file>
                  <div class="mt-2" v-if="form.attorneys[index].file.file">
                    Загруженный файл:
                    <a
                      @click.prevent="downloadFile(form.attorneys[index].file.file)"
                      :href="'/files/' + form.attorneys[index].file.file.guid"
                      target="_blank"
                    >{{form.attorneys[index].file.file.originalName}}</a>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <button class="btn btn-secondary" type="button" @click="addNewAttorneyFields">Добавить доверенность</button>
        </div-->
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          v-if="$store.getters.isAdminOrManager && data.draftDriver.status === 0"
          type="button"
          variant="success"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="customersDriverDraftsAccept()"
        >
          Принять
        </b-button>
        <b-button
          v-if="$store.getters.isAdminOrManager && data.draftDriver.status === 0"
          type="button"
          variant="danger"
          :class="{'loading' : loading}"
          :disabled="loading"
          @click="customersDriverDraftsDecline()"
        >
          Отклонить
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue'
import {validationMixin} from 'vuelidate';
import {required, sameAs} from 'vuelidate/lib/validators';
import notifications from '../../components/mixins/notifications';
import {
  passportTypesList,
  downloadFile,
  customersDriverDraftsRead,
  customersDriverDraftsAccept,
  customersDriverDraftsDecline,
} from '../../services/api';
import moment from 'moment';
import multiplePhonesInput from '../Forms/MultiplePhonesInput';

export default {
  name: 'CustomerDriverDraftsView',
  components: {multiplePhonesInput, ElDatePickerInput},
  mixins: [validationMixin, notifications],
  props: ['driverDraftId'],
  data() {
    return {
      passportTypes: [],
      data: {
        contractor: {},
        createdBy: {},
        draftDriver: {
          id: null,
          status: null,
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          passportScreenFirst: null,
          passportScreenSecond: null,
          driverLicense: '',
          contractFiles: [],
          driverLicenseScreens: [],
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
          driverPhones: [],
          type: null,
        },
        originalDriver: {
          id: null,
          passportType: null,
          passportSeries: '',
          passportNumber: '',
          passportIssueDate: '',
          passportIssuedBy: '',
          surname: '',
          name: '',
          patronymic: '',
          birthDate: '',
          passportScreenFirst: null,
          passportScreenSecond: null,
          driverLicense: '',
          contractFiles: [],
          driverLicenseScreens: [],
          driverAccessAuto: false,
          driverAccessContainer: false,
          driverAccessCargoFlow: false,
        },
        driverPhones: null,
      },
      form: {
        attorneys: [],
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  computed: {
    isValid() {
      return !this.$v.data.draftDriver.$anyError;
    },
  },
  mounted() {
    this.passportTypesList();
    this.customersDriverDraftsRead();
  },
  methods: {
    downloadFile,
    addNewAttorneyFields() {
      this.form.attorneys.push({
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: '',
      });
    },
    async passportTypesList(params) {
      this.loading = true;
      const response = await passportTypesList(params);
      if (response && response.status === 200) {
        this.passportTypes = response.data.items.map((type) => {
          return {
            value: type.id,
            text: type.type,
          };
        });
      }
      this.loading = false;
    },
    phoneValidate(e) {
      if (/_/.test(e.$model)) {
        e.$model = '';
      }
    },
    async customersDriverDraftsRead() {
      this.loading = true;
      const response = await customersDriverDraftsRead(this.driverDraftId);
      if (response && response.status === 200) {
        this.data.contractor = response.data.contractor;
        this.data.createdBy = response.data.createdBy;
        this.data.draftDriver = response.data;
        if (response.data.driverPhones) {
          this.data.driverPhones = JSON.parse(response.data.driverPhones);
        }
        if (response.data.passportType) {
          this.data.draftDriver.passportType = response.data.passportType.id;
        }
        if (response.data.birthDate) {
          this.data.draftDriver.birthDate = moment(response.data.birthDate).format('dd.MM.yyyy');
        }
        if (response.data.driver.birthDate) {
          this.data.originalDriver.birthDate = moment(response.data.driver.birthDate).format('dd.MM.yyyy');
        }
        this.data.originalDriver = response.data.driver;
        if (response.data.driver.passportType) {
          this.data.originalDriver.passportType = response.data.driver.passportType.id;
        }
      }
      this.loading = false;
    },
    async customersDriverDraftsAccept() {
      this.loading = true;
      const response = await customersDriverDraftsAccept(this.driverDraftId);
      if (response && response.status === 200) {
        this.showSuccess('Черновик принят');
        this.$router.push({path: '/driver-drafts'});
      }
      this.loading = false;
    },
    async customersDriverDraftsDecline() {
      this.loading = true;
      const response = await customersDriverDraftsDecline(this.driverDraftId);
      if (response && response.status === 200) {
        this.showSuccess('Черновик отклонен');
        this.$router.push({path: '/driver-drafts'});
      }
      this.loading = false;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
  },
};
</script>

<style lang="scss">
</style>

